import { ReactNode } from "react";
import { Flex } from "@radix-ui/themes";

interface CenterLayoutProps {
  children: ReactNode;
}

/** Places children in the center of the page */
export function CenterLayout({ children }: CenterLayoutProps) {
  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      style={{ height: "100dvh" }}
    >
      {children}
    </Flex>
  );
}
