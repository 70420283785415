import { classnames } from "utils/classnames";
import classes from "./Spinner.module.css";

interface SpinnerProps {
  size: "md" | "lg";
}

/** Loading spinner */
export function Spinner({ size = "md" }: SpinnerProps) {
  return <div className={classnames([classes.spinner, classes[size]])} />;
}
