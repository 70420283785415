import classes from "./Header.module.css";
import { Flex, Text, DropdownMenu, IconButton } from "@radix-ui/themes";
import { PersonIcon, SunIcon, MoonIcon } from "@radix-ui/react-icons";
import Logo from "./Logo.svg";
import { useUserAtom, RESET } from "atoms";
import { useTheme } from "atoms";

/** App header */
export function Header() {
  return (
    <Flex height="8" gap="4" p="2" justify="between" className={classes.Header}>
      <Flex align="center" gap="2">
        <img src={Logo} className={classes.logo} />
        <Text size="5" weight="bold" className={classes.text}>
          Click Maint Admin
        </Text>
      </Flex>
      <Flex gap="1">
        <ThemeToggle />
        <ProfileDropdown />
      </Flex>
    </Flex>
  );
}

function ThemeToggle() {
  const [theme, setTheme] = useTheme();
  if (theme === "light") {
    return (
      <IconButton variant="outline" onClick={() => setTheme("dark")}>
        <MoonIcon />
      </IconButton>
    );
  } else {
    return (
      <IconButton variant="outline" onClick={() => setTheme("light")}>
        <SunIcon />
      </IconButton>
    );
  }
}

function ProfileDropdown() {
  const [, setUser] = useUserAtom();
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <IconButton variant="outline">
          <PersonIcon />
        </IconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item onSelect={() => setUser(RESET)}>
          Sign out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
