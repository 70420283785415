import { configureStore } from "@reduxjs/toolkit";
import { CMMSAPI } from "app/services";
import { handleUnauthorized } from "app/middleware/handleUnauthorized";

export default configureStore({
  reducer: {
    [CMMSAPI.reducerPath]: CMMSAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([handleUnauthorized, CMMSAPI.middleware]),
});
