import { Theme as RadixTheme } from "@radix-ui/themes";
import { ReactNode, useEffect, useLayoutEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import type { Theme } from "types";
import { useTheme } from "atoms";

interface ThemeProps {
  /** Sets the theme statically, only used for Storybook. */
  override?: Theme;
  children: ReactNode;
}

/**
 * Sets light/dark mode globally, and manages Radix and react-loading-skeleton themes.
 *
 * Stores user preference in localStorage. If the user hasn't interacted with the theme control,
 *    defaults to using the system setting.
 */
export function Theme({ override, children }: ThemeProps) {
  const [theme, setTheme] = useTheme();

  useLayoutEffect(() => {
    if (override && override !== theme) {
      setTheme(override);
    }
  }, [override, theme, setTheme]);

  useEffect(() => {
    const handleThemeChange = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? "dark" : "light");
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleThemeChange);
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handleThemeChange);
    };
  }, [setTheme]);

  useLayoutEffect(() => {
    document.body.classList.remove(theme === "dark" ? "light" : "dark");
    document.body.classList.add(theme);
  }, [theme]);

  return (
    <RadixTheme accentColor="teal">
      <SkeletonTheme
        baseColor={theme === "dark" ? "#2a2a2a" : undefined}
        highlightColor={theme === "dark" ? "#333333" : undefined}
      >
        {children}
      </SkeletonTheme>
    </RadixTheme>
  );
}
