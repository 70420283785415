import { ReactNode, ComponentPropsWithoutRef } from "react";
import classes from "./FadeIn.module.css";
import { classnames } from "utils/classnames";

interface FadeInProps extends ComponentPropsWithoutRef<"div"> {
  duration?: number;
  delay?: number;
  children: ReactNode;
}

/**
 * Fades in content on mount.
 * Borrowed from [Josh Comeau](https://www.joshwcomeau.com/snippets/react-components/fade-in/).
 */
export function FadeIn({
  duration,
  delay,
  children,
  className,
  style,
  ...delegated
}: FadeInProps) {
  return (
    <div
      {...delegated}
      className={classnames([classes.fadeIn, className])}
      style={{
        ...(style || {}),
        animationDuration: duration + "ms",
        animationDelay: delay + "ms",
      }}
    >
      {children}
    </div>
  );
}
