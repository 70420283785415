import { atomEffect } from "jotai-effect";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import * as Sentry from "@sentry/browser";
import type { User } from "types";

const userAtom = atomWithStorage<User | undefined>(
  "user",
  undefined,
  undefined,
  { getOnInit: true },
);

/** Keeps Sentry user up-to-date with useAtom state */
const updateUserEffect = atomEffect((get) => {
  const user = get(userAtom);
  if (user) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      name: user.full_name,
    });
  } else {
    Sentry.setUser(null);
  }
});

/** Stores the currently signed-in User data in localStorage */
export function useUserAtom() {
  useAtom(updateUserEffect);
  return useAtom(userAtom);
}
