import { ListenerMiddleware } from "@reduxjs/toolkit";

/** If any request responds with a 401, redirect to the signin page */
export const handleUnauthorized: ListenerMiddleware =
  () => (next) => (action) => {
    if (action.error && action.payload?.status === 401) {
      window.location.href = "/signin";
      localStorage.removeItem("user");
    } else {
      return next(action);
    }
  };
