import { Spinner } from "components/Spinner";
import { Heading, Text, Button } from "@radix-ui/themes";
import { FadeIn } from "components/FadeIn";
import { useGetUserMutation } from "app/services";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserAtom, useRestorePrevLocationAtom, RESET } from "atoms";
import { CenterLayout } from "components/CenterLayout";

/** Shows a loading spinner and handles auth logic after signing in with SSO */
export function LandingPage() {
  const [getUser, { error }] = useGetUserMutation();
  const [user, setUser] = useUserAtom();
  const [redirect, setRedirect] = useRestorePrevLocationAtom();
  const navigate = useNavigate();

  useEffect(() => {
    // When we land on the landing page, query for and set the user data
    getUser().unwrap().then(setUser);
  }, [getUser, setUser, user]);

  if (user) {
    // Once we have a user's data, redirect to the last page they were on, or the tenants list
    setRedirect(RESET);
    return <Navigate to={redirect ?? "/tenants"} replace relative="path" />;
  } else if (error) {
    return (
      <CenterLayout>
        <Heading>Sign in failed</Heading>
        <Text>Please try again</Text>
        <Button
          size="1"
          variant="outline"
          onClick={() => navigate("/signin")}
          mt="4"
        >
          Sign In
        </Button>
      </CenterLayout>
    );
  }

  return (
    <CenterLayout>
      <FadeIn duration={1000} delay={500}>
        <Spinner size="lg" />
      </FadeIn>
    </CenterLayout>
  );
}
