import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers) => {
    const { token } = JSON.parse(localStorage.getItem("user") || "{}");

    if (token) {
      headers.set("Authorization", token);
    }

    return headers;
  },
  paramsSerializer: (params) =>
    qs.stringify(params, {
      encode: false,
      arrayFormat: "brackets",
    }),
});

export const CMMSAPI = createApi({
  reducerPath: "cmms-api",
  baseQuery,
  keepUnusedDataFor: 10 * 60,
  tagTypes: ["tenants"],
  endpoints: () => ({}),
});
