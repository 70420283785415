import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import type { Theme } from "types";

const themeAtom = atomWithStorage<Theme>(
  "theme",
  window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
  undefined,
  { getOnInit: true },
);

/** Get/set the app theme */
export function useTheme() {
  return useAtom(themeAtom);
}
