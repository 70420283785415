import { CMMSAPI } from "./cmms-api";
import type { Response, User } from "types";

const userApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.mutation<User, void>({
      query: () => ({ url: "users/current", credentials: "include" }),
      transformResponse: (res: Response<User>, meta) => {
        const authToken = meta?.response?.headers.get("Authorization");

        if (!authToken || !res.data) {
          throw new Error("Sign in failed");
        }

        return {
          ...res.data,
          token: authToken ?? "",
        };
      },
    }),
  }),
});

export const { useGetUserMutation } = userApi;
