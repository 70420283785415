import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "@radix-ui/themes/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import "./index.css";
import { Root } from "pages/Root/Root.tsx";
import store from "app/store";
import { LandingPage } from "pages/LandingPage";
import { SignInPage } from "pages/SignInPage";
import { Theme } from "components/Theme";

const router = createBrowserRouter([
  {
    path: "landing",
    element: <LandingPage />,
  },
  {
    path: "signin",
    element: <SignInPage />,
  },
  {
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate to="tenants" replace />,
      },
      {
        path: "tenants",
        lazy: () => import("pages/TenantListPage"),
      },
      {
        path: "tenants/:tenantId",
        lazy: () => import("pages/TenantPage"),
      },
      {
        path: "*",
        lazy: () => import("pages/NotFound"),
      },
    ],
  },
]);

if (import.meta.env.PROD) {
  Sentry.init({
    release: import.meta.env.VITE_RELEASE,
    environment: import.meta.env.VITE_APP_ENV,
    dsn: "https://3ec68bb25939607c99fe981756e73536@o4506373586288640.ingest.sentry.io/4506423351246848",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

/**
 * Set up MSW when in DEV mode
 * See the [MSW Docs](https://mswjs.io/docs/integrations/browser) for more information
 */
async function enableMocking() {
  if (import.meta.env.PROD || import.meta.env.VITE_MOCK_API !== "true") {
    return;
  }

  const { worker } = await import("app/mocks/browser");
  const allowUnhandled: RegExp[] = [/\.svg$/];

  return worker.start({
    onUnhandledRequest(req, print) {
      if (allowUnhandled.some((r) => req.url.match(r))) {
        return;
      }

      print.warning();
    },
  });
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <Provider store={store}>
        <Theme>
          <RouterProvider router={router} />
        </Theme>
      </Provider>
    </React.StrictMode>,
  );
});
