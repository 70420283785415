import { Flex, Button, Heading } from "@radix-ui/themes";
import { CenterLayout } from "components/CenterLayout";

export function SignInPage() {
  return (
    <CenterLayout>
      <Flex align="center" gap="4" direction="column">
        <Heading>Sign In</Heading>
        <Button
          onClick={() => {
            if (import.meta.env.VITE_MOCK_API === "true") {
              window.location.href = new URL(
                "landing",
                window.location.origin,
              ).toString();
              return;
            }
            window.location.href = getRedirectURL();
          }}
        >
          Continue
        </Button>
      </Flex>
    </CenterLayout>
  );
}

function getRedirectURL() {
  let authURL = new URL("oauth/init", import.meta.env.VITE_API_URL);

  if (import.meta.env.DEV) {
    authURL = new URL("oauth/test_init", import.meta.env.VITE_API_URL);
  }

  authURL.searchParams.append(
    "landing_url",
    `${window.location.origin}/landing`,
  );
  return authURL.toString();
}
