import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const restorePrevLocationAtom = atomWithStorage<string | undefined>(
  "landingRedirect",
  undefined,
);

/** Helper for redirecting the user after signing in. */
export function useRestorePrevLocationAtom() {
  return useAtom(restorePrevLocationAtom);
}
